import axios from 'axios';

export async function getCredits(uid) {
    const data = {
        "params": {
            uid: uid
        }
    }
    const response = await axios.get('/v1/credits', data);
    return response.data;
}

export async function getFreeCredits() {
    const response = await axios.get('/v1/credits/free');
    return response.data;
}
