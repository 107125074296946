import React, { useState, useEffect } from 'react';
import Chat from '../components/chat/chat';
import TopChatMenu from '../components/chat/topChatMenu/topChatMenu';

import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button'

import RightMenu from '../components/rightMenu/rightMenu'

import './chatPage.css'

export default function ChatPage({toastRef}) {

    let { AIName } = useParams();
    const [resetChat, setResetChat] = useState(false);

    const [isMenuVisible, setIsMenuVisible] = useState(window.innerWidth >= 600);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);

    // Gérer les changements de taille d'écran
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 600);
            if (window.innerWidth >= 600) {
                setIsMenuVisible(true);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResetChat = () => {
        setResetChat(true);
        setTimeout(() => setResetChat(false), 0);
    };

    const handleButtonClick = () => {
        setIsMenuVisible(!isMenuVisible);
    }

    return(
        <div className='h-full chatPage'>
            <div className="mainChatWidth">
                <div className="mainTopChatMenu">
                    <TopChatMenu AIName={AIName} askDeleteMessages={handleResetChat}/>
                </div>
                <div className="mainChat">
                    <Chat AIName={AIName} resetChat={resetChat} toastRef={toastRef}/>
                </div> 
            </div>
            <div className={`mainRightMenuWidth ${isMenuVisible ? 'visible' : 'hidden'}`}>
                <RightMenu AIName={AIName}/>
            </div>
            {isSmallScreen && (
                <Button
                    id="toggleMenuButton"
                    className="toggle-menu-button"
                    icon={isMenuVisible ? 'pi pi-angle-double-right' : 'pi pi-angle-double-left'}
                    onClick={handleButtonClick}
                />
            )}
        </div>
    )
} 