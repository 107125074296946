import { useState } from 'react';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { useNavigate } from 'react-router-dom';

import 'firebaseui/dist/firebaseui.css';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import './register.css';

export default function Register({toastRef}) {

    const auth = getAuth();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const navigate = useNavigate();
    
    const register = () => {
        if(password !== confirmPassword){
            toastRef.current.show({severity:'error', summary: 'Error', detail:'Your password doesn\'t match', life: 5000})
        } else {
            createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                 // Envoyez un e-mail de vérification
                sendEmailVerification(user)
                .then(() => {
                    toastRef.current.show({severity:'success', summary: 'Success', detail:'A validation email has been sent. Please check your inbox.', life: 5000})
                    navigate('/signin')
                })
                .catch((error) => {
                    toastRef.current.show({severity:'error', summary: 'Error', detail:'There was an error sending the validation email. Please try again later.', life: 5000})
                });
            })
            .catch((error) => {
                const errorCode = error.code;
                if(errorCode === "auth/email-already-in-use"){
                    toastRef.current.show({severity:'error', summary: 'Error', detail:'This email address is already registered. Please sign in.', life: 5000})
                } else if(errorCode === "auth/weak-password"){
                    toastRef.current.show({severity:'error', summary: 'Error', detail:'Your password must be at least 6 characters long.', life: 5000})
                } else if(errorCode === "auth/invalid-email"){
                    toastRef.current.show({severity:'error', summary: 'Error', detail:'Please enter a valid email address.', life: 5000})
                }
            });
        }
    }

    return (
        <div className="flex align-items-center justify-content-center h-full">
            <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
                <div className="text-center mb-5">
                    <div className="text-900 text-3xl font-medium mb-3">Welcome</div>
                    <span className="text-600 font-medium line-height-3">Already have an account?</span>
                    <a className="font-medium no-underline ml-2 cursor-pointer pinkcolortext"  onClick={() => navigate('/signin')}>Sign In!</a>
                </div>

                <div>
                    <label htmlFor="email" className="block text-900 font-medium mb-2">Email</label>
                    <InputText id="email" type="text" placeholder="Email address" onChange={(e) => setEmail(e.target.value)} value={email} className="w-full mb-3" />

                    <label htmlFor="password" className="block text-900 font-medium mb-2">Password</label>
                    <InputText id="password" type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} value={password} className="w-full mb-3" />

                    <label htmlFor="password" className="block text-900 font-medium mb-2">Confirm password</label>
                    <InputText id="password" type="password" placeholder="Password" onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} className="w-full mb-3" />

                    <div className='flex justify-content-center'>
                        <p>By registering, you confirm that you are<span className="pinkcolortext"> 18 years old or more.</span></p>
                    </div>
                    <Button label="Register" icon="pi pi-user" className="w-full pingcolorbackground" onClick={register}/>
                </div>
            </div>
        </div>
    )

}